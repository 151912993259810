"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function storageAvailable(type) {
  var storage;
  try {
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
    // everything except Firefox
    e.code === 22 ||
    // Firefox
    e.code === 1014 ||
    // test name field too, because code might not be present
    // everything except Firefox
    e.name === 'QuotaExceededError' ||
    // Firefox
    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
    // acknowledge QuotaExceededError only if there's something already stored
    storage && storage.length !== 0;
  }
}
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
if ('objectFit' in document.documentElement.style === false) {
  document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('img[data-object-fit]').forEach(function (image) {
      (image.runtimeStyle || image.style).background = "url(\"".concat(image.src, "\") no-repeat 50%/").concat(image.currentStyle ? image.currentStyle['object-fit'] : image.getAttribute('data-object-fit'));
      image.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(image.width, "' height='").concat(image.height, "'%3E%3C/svg%3E");
    });
  });
}
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;
    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
function getTranslationsFromLang() {
  var currLang = document.querySelector('body').getAttribute('data-current-lang');
  var ajaxParams = [];
  for (var i = 0; i < arguments.length; i++) {
    ajaxParams.push([arguments[i]]);
  }
  var formData = 'params=[' + ajaxParams.map(function (key) {
    return "[\"".concat(key, "\", \"").concat(currLang, "\"]");
  }).join(',').replace(/%20/g, '+') + ']';
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '//' + window.location.host + '/langTranslation/', false);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send(formData);
  if (xhr.status === 200) {
    return JSON.parse(xhr.responseText);
  } else {
    var _xhr = new XMLHttpRequest();
    _xhr.open('POST', '//' + window.location.href + "main.js:getTranslationsFromLang()\n\n Languages access has failed");
    _xhr.send(null);
  }
}
var translations = getTranslationsFromLang('form field warning', 'form date callendar button prev', 'form date callendar button next', 'form field cyrillic warning', 'form field cyrillic warning2', 'Please contact suport', 'transaction-response settled', 'missing data', 'Menu 3 Link 1', 'form label nonlatin error');

//////////////////////////////
// show phone number on page
//////////////////////////////

function send_to_logger(message) {
  var formData = new FormData();
  formData.append('message', message);
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '//' + window.location.host + '/logger.php');
  xhr.send(formData);
}
function getPhoneNumbers() {
  var formData = new FormData();
  formData.append('language', document.body.dataset.currentLang);
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '//' + window.location.host + '/get-phone-number/', true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send("language=".concat(document.body.dataset.currentLang));
  xhr.onreadystatechange = function (e) {
    if (this.readyState === 4) {
      if (this.status === 200) {
        var data = JSON.parse(this.responseText);
        if (data.status) {
          showPagePhone(data.numbers);
        } else {
          var toRemove = document.querySelectorAll('.phone-number.callus-single, .phone-number.dropdown, .phone-numbers-container');
          for (var i; i < toRemove.length; i++) {
            toRemove[i].parentElement.removeChild(toRemove[i]);
          }
        }
      } else {
        send_to_logger("JSERR " + window.location.href + "main.js:getPhoneNumbers() Phone numbers access has been failed");
      }
    }
  };
}
function showPagePhone(phoneNumbers) {
  if (document.querySelector('html').dataset.currentLang === 'pl') {
    return;
  }
  if (typeof phoneNumbers[0] !== "undefined") {
    var primaryPhone = phoneNumbers[0],
      headerPhoneAdditional = document.querySelectorAll('.navbar-phone-link-additional'),
      callusIcon = document.querySelector('.callus-icon');
    addPhoneToHeaderElements(document.querySelectorAll('.navbar-phone-link-primary'), primaryPhone);
    addPhoneToCallusElements(document.querySelectorAll('.callus-primary'), primaryPhone);
    if (document.querySelector('#footer-tel-link')) {
      document.querySelector('#footer-tel-link').setAttribute('href', 'tel:' + primaryPhone.raw_number);
      document.querySelector('#footer-tel-link').innerText = primaryPhone.number;
    }
    if (typeof phoneNumbers[1] !== "undefined") {
      var additionalPhone = phoneNumbers[1];
      addPhoneToHeaderElements(headerPhoneAdditional, additionalPhone);
      if (document.querySelector('.phone-number')) {
        addPhoneToCallusElements(document.querySelectorAll('.callus-additional'), additionalPhone);
        document.querySelector('.phone-number.callus-single').parentNode.removeChild(document.querySelector('.phone-number.callus-single'));
        document.querySelector('.phone-number.dropdown').classList.remove('phone-loading-hidden');
      }
    } else {
      if (callusIcon) {
        callusIcon.setAttribute('href', 'tel:' + primaryPhone.raw_number);
      }
      if (document.querySelector('.phone-number.dropdown')) {
        document.querySelector('.phone-number.dropdown').parentNode.removeChild(document.querySelector('.phone-number.dropdown'));
      }
      for (var i = 0; i < headerPhoneAdditional.length; i++) {
        headerPhoneAdditional[i].parentNode.removeChild(headerPhoneAdditional[i]);
      }
    }
  }
}
function addPhoneToHeaderElements(header, data) {
  for (var i = 0; i < header.length; i++) {
    if (header[i].querySelector(".area-code")) {
      header[i].querySelector(".area-code").innerText = data.number.trim().substr(0, 3);
    }
    if (header[i].querySelector(".number")) {
      header[i].querySelector(".number").innerText = data.number.trim().substr(3);
    }
    if (header[i].querySelector(".language")) {
      header[i].querySelector(".language").innerText = '(' + data.title + ')';
    }
  }
}
function addPhoneToCallusElements(callus, data) {
  for (var i = 0; i < callus.length; i++) {
    callus[i].setAttribute('href', 'tel:' + data.raw_number);
    if (callus[i].querySelector('.img-lang')) {
      callus[i].querySelector('.img-lang').setAttribute('src', '/assets/img/lang/' + data.code.toLowerCase() + '.svg');
    }
  }
}
getPhoneNumbers();
deferImages();
if (storageAvailable('localStorage') && (typeof localStorage === "undefined" ? "undefined" : _typeof(localStorage)) === 'object') {
  try {
    localStorage.setItem('localStorage', 1);
    localStorage.removeItem('localStorage');
  } catch (e) {
    Storage.prototype._setItem = Storage.prototype.setItem;
    Storage.prototype.setItem = function () {};
  }
}

//
// Dropdowns menu
//
dropdownMenusSetup();
var supportEmailEl = document.querySelector('.support-email');
if (supportEmailEl) {
  supportEmailEl.addEventListener('click', function () {
    supportEmailEl.setAttribute('href', 'mailto:' + translations['Menu 3 Link 1']);
  });
  supportEmailEl.innerHTML = translations['Menu 3 Link 1'];
}
function dropdownMenusSetup() {
  var languages = document.querySelector('#dropdown-language');
  var phones = document.querySelector('#dropdown-phone-list');
  if (languages) {
    document.querySelector('#dropdown03').addEventListener('click', function () {
      languages.setAttribute('aria-hidden', languages.getAttribute('aria-hidden') === "true" ? "false" : "true");
    });
    document.querySelector('#dropdown04').addEventListener('click', function () {
      languages.setAttribute('aria-hidden', languages.getAttribute('aria-hidden') === "true" ? "false" : "true");
    });
  }
  if (languages && phones) {
    document.addEventListener('click', function (event) {
      if (event.target && !event.target.closest('#dropdown03')) {
        languages.setAttribute('aria-hidden', 'true');
      }
      if (event.target && !event.target.closest('#dropdown-phone-button') && phones) {
        phones.setAttribute('aria-hidden', 'true');
      }
    });
  }
}

// for SEO
// add class "defer-img" and data-src="src" to defer image, src set to "defer.png"
function deferImages() {
  var imgDefer = document.querySelectorAll('img.defer-img');
  if (imgDefer.length > 0) {
    var src = '';
    for (var _i = 0; _i < imgDefer.length; _i++) {
      src = imgDefer[_i].getAttribute('data-src');
      imgDefer[_i].setAttribute('src', src);
    }
  }
}
if (document.querySelector('.expand-info-content .expandable-content')) {
  document.querySelector('.expand-info-content .expandable-content').classList.add('expanded');
}
if (document.querySelector('.expand-info-content .expandable-content')) {
  document.querySelector('.expand-info-content .expandable-content').querySelector('.expand-content').classList.remove('hidden');
}
var expandSwitchers = document.querySelectorAll('.expand-switcher');
var _loop = function _loop() {
  var expandSwitcher = expandSwitchers[i];
  if (!expandSwitcher.closest('.expandable-content.expanded')) {
    expandSwitcher.closest('.expandable-content').style.height = getComputedStyle(expandSwitcher).height;
  }
  expandSwitcher.addEventListener('click', function (e) {
    var target = e.target.closest('.expandable-content');
    var expanded = target.classList.contains('expanded');
    if (!target.classList.contains('disabled')) {
      if (!expanded) {
        target.classList.add('arrow-expanded');
        target.style.height = parseInt(getComputedStyle(target.querySelector('.expand-switcher')).height) + parseInt(calcElementHeight(target.querySelector('.expand-content'))) + 'px';
        target.classList.add('expanded');
      } else {
        target.classList.remove('arrow-expanded');
        target.style.height = parseInt(getComputedStyle(target.querySelector('.expand-switcher')).height) + parseInt(calcElementHeight(target.querySelector('.expand-content'))) + 'px';
        target.style.height = parseInt(getComputedStyle(expandSwitcher).height) + 2 + 'px';
        setTimeout(function () {
          target.classList.remove('expanded');
        }, 300);
      }
    }
  });
  expandSwitcher.closest('.expandable-content').addEventListener('transitionstart', function () {
    this.classList.add('disabled');
  });
  expandSwitcher.closest('.expandable-content').addEventListener('transitionend', function () {
    this.classList.remove('disabled');
    if (this.classList.contains('expanded')) {
      this.style.height = 'auto';
    }
  });
};
for (var i = 0; i < expandSwitchers.length; i++) {
  _loop();
}
var expandInfoBtn = document.querySelectorAll('.expand-info-btn');
var calcElementHeight = function calcElementHeight(el) {
  var save = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var style = el.getAttribute('style') || '';
  el.setAttribute('style', 'display: block;');
  var height = window.getComputedStyle(el).height;
  el.setAttribute('style', style);
  if (save) {
    el.dataset.height = height;
  }
  return height;
};
if (document.querySelector('.expand-info-content')) {
  calcElementHeight(document.querySelector('.expand-info-content'), true);
  window.addEventListener('resize', function (e) {
    calcElementHeight(document.querySelector('.expand-info-content'), true);
    for (var i = 0; i < expandSwitchers.length; i++) {
      var expandSwitcher = expandSwitchers[i];
      if (!expandSwitcher.closest('.expandable-content.expanded')) {
        expandSwitcher.closest('.expandable-content').style.height = getComputedStyle(expandSwitcher).height;
      }
    }
  });
}
for (var i = 0; i < expandInfoBtn.length; i++) {
  var button = expandInfoBtn[i];
  button.addEventListener('click', function () {
    if (document.querySelector('.expand-info-content').classList.contains('expanded')) {
      var actualHeight = parseInt(getComputedStyle(document.querySelector('.expand-info-content')).height);
      var timer = setInterval(function () {
        actualHeight -= 20;
        document.querySelector('.expand-info-content').style.height = actualHeight + 'px';
        if (actualHeight <= 0) {
          clearInterval(timer);
          document.querySelector('.expand-info-content').style.display = 'none';
        }
      }, 1);
    } else {
      var height = parseInt(calcElementHeight(document.querySelector('.expand-info-content')));
      var _actualHeight = 0;
      document.querySelector('.expand-info-content').style.height = '0px';
      document.querySelector('.expand-info-content').style.display = 'block';
      for (var i = 0; i < expandSwitchers.length; i++) {
        var expandSwitcher = expandSwitchers[i];
        if (!expandSwitcher.closest('.expandable-content.expanded')) {
          expandSwitcher.closest('.expandable-content').style.height = getComputedStyle(expandSwitcher).height;
        }
      }
      var _timer = setInterval(function () {
        _actualHeight += 20;
        document.querySelector('.expand-info-content').style.height = _actualHeight + 'px';
        if (_actualHeight >= height) {
          clearInterval(_timer);
          document.querySelector('.expand-info-content').style.height = 'auto';
        }
      }, 1);
    }
    document.querySelector('.expand-info-content').classList.toggle('expanded');
  });
}
if (document.querySelector('.support-email')) {
  document.querySelector('.support-email').setAttribute('href', 'mailto:' + document.querySelector('.support-email').innerText);
}
function accordionMenu() {
  var dropdownButtons = document.querySelectorAll('.dropdown-btn');
  dropdownButtons.forEach(function (button) {
    button.addEventListener('click', function (e) {
      button.classList.toggle('active');
      button.nextElementSibling.classList.toggle('hide');
    });
  });
}
window.addEventListener("DOMContentLoaded", function () {
  accordionMenu();
});